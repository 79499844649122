






































import {
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";

export default defineComponent({
  props: {
    value: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  setup(props, { root }) {
    const state = reactive({
      loading: false,
      additionalServices: [],
      operations: {
        notIn: false,
        or: true,
        and: false,
      },
    });

    const fetchAdditionalServices = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}/additional-service-group/select`, {
          params: { sortBy: ["name"], sortDesc: [false] },
        })
        .then(({ data: { additionalServiceGroups } }) => {
          state.additionalServices = additionalServiceGroups
            .map((el: any) => ({
              services: el.additionalServices.map((item: any) => ({
                id: item.id,
                name: item.name,
                groupName: el.name,
              })),
            }))
            .map((el: any) => el.services)
            .flat();
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.additionalServices = [];
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loading = false;
        });
    };

    onMounted(fetchAdditionalServices);

    const selectAllServices = () => {
      props.value.values = state.additionalServices.map((el: any) => el.id);
    };

    const deleteItem = (item: any) => {
      props.value.values = props.value.values.filter((el: any) => el !== item);
    };

    watch(
      () => state.operations.or,
      () => {
        if (state.operations.or) {
          props.value.operation = "or";
          state.operations.and = false;
        }
      }
    );

    watch(
      () => state.operations.notIn,
      () => {
        if (state.operations.or && state.operations.notIn) {
          props.value.operation = "notInWithOr";
          state.operations.and = false;
        } else if (state.operations.notIn) {
          props.value.operation = "notInWithOr";
          state.operations.and = false;
          state.operations.or = true;
        } else if (!state.operations.notIn && state.operations.or) {
          props.value.operation = "or";
        }
      }
    );

    watch(
      () => state.operations.and,
      () => {
        if (state.operations.and) {
          props.value.operation = "and";
          state.operations.notIn = false;
          state.operations.or = false;
        } else if (!state.operations.and && state.operations.notIn) {
          props.value.operation = "notInWithOr";
        }
      }
    );

    return { state, selectAllServices, deleteItem };
  },
});
